import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const Services = () => {
  const data = useStaticQuery(graphql`
    {
      domainServices: contentfulHomePageServices(
        title: { eq: "Domain Services" }
      ) {
        image {
          file {
            url
            fileName
          }
        }
        title
        servicesList {
          childContentfulRichText {
            html
          }
        }
      }

      websiteServices: contentfulHomePageServices(
        title: { eq: "Website Services" }
      ) {
        image {
          file {
            url
            fileName
          }
        }
        title
        servicesList {
          childContentfulRichText {
            html
          }
        }
      }

      strategyServices: contentfulHomePageServices(
        title: { eq: "Strategy Services" }
      ) {
        image {
          file {
            url
            fileName
          }
        }
        title
        servicesList {
          childContentfulRichText {
            html
          }
        }
      }
    }
  `)

  const domainServices = data.domainServices
  const websiteServices = data.websiteServices
  const strategyServices = data.strategyServices

  return (
    <section id="index-services-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={3} md={4} className="service-wrapper">
            <Fade cascade duration={2500}>
              <div className="service-head">
                <img
                  src={domainServices.image.file.url}
                  alt={domainServices.image.file.fileName}
                />
                <h3>01 – Services</h3>
                <h2
                  dangerouslySetInnerHTML={{ __html: domainServices.title }}
                />
              </div>

              <div
                className="service-list"
                dangerouslySetInnerHTML={{
                  __html:
                    domainServices.servicesList.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>

          <Col lg={3} md={4} className="service-wrapper offset-lg-1">
            <Fade cascade duration={2500}>
              <div className="service-head">
                <img
                  src={websiteServices.image.file.url}
                  alt={websiteServices.image.file.fileName}
                />
                <h3>02 – Services</h3>
                <h2
                  dangerouslySetInnerHTML={{ __html: websiteServices.title }}
                />
              </div>

              <div
                className="service-list"
                dangerouslySetInnerHTML={{
                  __html:
                    websiteServices.servicesList.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>

          <Col lg={3} md={4} className="service-wrapper offset-lg-1">
            <Fade cascade duration={2500}>
              <div className="service-head">
                <img
                  src={strategyServices.image.file.url}
                  alt={strategyServices.image.file.fileName}
                />
                <h3>03 – Services</h3>
                <h2
                  dangerouslySetInnerHTML={{ __html: strategyServices.title }}
                />
              </div>

              <div
                className="service-list"
                dangerouslySetInnerHTML={{
                  __html:
                    strategyServices.servicesList.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services
