import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const AboutSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        edges {
          node {
            aboutSectionImage {
              file {
                url
                fileName
              }
            }
            aboutSectionSloganText
            aboutSectionTitleText
            aboutSectionDescription {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
    }
  `)

  const aboutData = data.allContentfulHomePage.edges[0].node

  return (
    <section id="about-section">
      <Container>
        <Row>
          <Col className="text-center">
            <Fade cascade duration={2500}>
              <div className="about-img-sec">
                <img
                  src={aboutData.aboutSectionImage.file.url}
                  alt={aboutData.aboutSectionImage.file.fileName}
                  className="mb-4"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutData.aboutSectionSloganText,
                  }}
                />
              </div>
            </Fade>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={7} md={10} className="text-center">
            <Fade cascade duration={2500}>
              <div className="about-dics-sec mt-4">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: aboutData.aboutSectionTitleText,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      aboutData.aboutSectionDescription.childContentfulRichText
                        .html,
                  }}
                />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutSection
