import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const Registrar = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulRegistrarLogo {
        nodes {
          id
          logo {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `)

  const registrarList = data.allContentfulRegistrarLogo.nodes

  return (
    <section id="registrar-section">
      <Container>
        <Row>
          {registrarList.map((registrat, index) => (
            <Col md={4} key={index}>
              <Fade cascade duration={2500}>
                <img
                  src={registrat.logo.file.url}
                  alt={registrat.logo.file.fileName}
                  className="mx-auto d-block"
                />
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Registrar
