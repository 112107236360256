import React from "react"
import HeroSection from "./hero-section"
import Testimonials from "./testimonials"
import Services from "./services"
import CaseStudy from "./case-study"
import Registrar from "./registrar"
import AboutSection from "./about-section"
import AddressesSection from "./addresses"

const Home = () => {
  return (
    <>
      <HeroSection />
      <Testimonials />
      <Services />
      <CaseStudy />
      <Registrar />
      <AboutSection />
      <AddressesSection />
    </>
  )
}

export default Home
