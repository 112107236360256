import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"

const IndexPage = ({ data }) => {
  const metaData = data.allContentfulHomePage.nodes[0]

  return (
    <Layout>
      <SEO title=".se .fi .dk .nu domain drop-catching & snapback " description={metaData.metaDescription} />
      <Home />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulHomePage {
      nodes {
        metaDescription
      }
    }
  }
`

export default IndexPage
