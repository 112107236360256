import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const CaseStudy = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCaseStudy(sort: { order: ASC, fields: createdAt }) {
        nodes {
          id
          name
          link
          description
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)

  const caseStudyList = data.allContentfulCaseStudy.nodes

  return (
    <section id="case-study-section">
      <Container fluid>
        <Row className="case-studies-wrapper">
          {caseStudyList.map(caseStudy => (
            <Col
              md={6}
              key={caseStudy.id}
              className="case-study"
              style={{
                background: `url(${caseStudy.image.file.url})`,
                overflow: "hidden",
              }}
            >
              <Fade cascade duration={2500}>
                <div className="case-study-head">
                  <h3>Case</h3>
                  <h2 dangerouslySetInnerHTML={{ __html: caseStudy.name }} />
                </div>
                <div className="case-study-body">
                  <p
                    dangerouslySetInnerHTML={{ __html: caseStudy.description }}
                  />
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default CaseStudy
