import React, { useRef, useEffect } from "react"
import lottie from "lottie-web"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import heroAnimatedImage from "../../../anim-files/domain-animation.json"
import "./_index.scss"

const HeroSection = () => {
  let animationContainer = useRef()

  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage(limit: 1) {
        edges {
          node {
            heroShortTitle
            heroTitle
            heroSectionButtonText
          }
        }
      }
    }
  `)

  const herosecData = data.allContentfulHomePage.edges[0].node

  useEffect(() => {
    if (!animationContainer) {
      return
    }
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: heroAnimatedImage,
    })

    return () => anim.destroy()
  }, [animationContainer])

  return (
    <section id="hero-section">
      <Container>
        <Row>
          <Col lg={6} md={7} className="hero-text my-auto position-relative">
            <h2
              dangerouslySetInnerHTML={{ __html: herosecData.heroShortTitle }}
            />
            <h1 dangerouslySetInnerHTML={{ __html: herosecData.heroTitle }} />
            <Link to="/domain-services">
              <Button className="hero-cta-btn">
                {herosecData.heroSectionButtonText}
              </Button>
            </Link>
          </Col>

          <Col lg={6} md={5} className="hero-img">
            <div className="animated-container" ref={animationContainer} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeroSection
