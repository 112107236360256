import React from "react"
import Slider from "react-slick"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const Testimonials = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage(limit: 1) {
        edges {
          node {
            testimonialsSectionImage {
              file {
                url
                fileName
              }
            }
            testimonialsSectionTitle
          }
        }
      }
      allContentfulTestimonials {
        nodes {
          id
          text {
            childContentfulRichText {
              html
            }
          }
          author
          designation
        }
      }
    }
  `)

  const testimonialsData = data.allContentfulHomePage.edges[0].node
  const testimonialsList = data.allContentfulTestimonials.nodes

  return (
    <section id="testimonials-section">
      <Container>
        <Row>
          <Col className="testimonial-head text-center">
            <Fade cascade duration={2500}>
              <img
                className="img-fluid"
                src={testimonialsData.testimonialsSectionImage.file.url}
                alt={testimonialsData.testimonialsSectionImage.file.fileName}
              />
              <h2
                dangerouslySetInnerHTML={{
                  __html: testimonialsData.testimonialsSectionTitle,
                }}
              />
            </Fade>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={9}>
            <Fade cascade duration={2500}>
              <Slider {...settings}>
                {testimonialsList.map(testimonial => (
                  <div
                    className="testimonial-text text-center"
                    key={testimonial.id}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: testimonial.text.childContentfulRichText.html,
                      }}
                    />

                    <div className="testimony">
                      <h3
                        dangerouslySetInnerHTML={{ __html: testimonial.author }}
                      />
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: testimonial.designation,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials
